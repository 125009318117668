import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
import Downloads from "../components/_ui/Downloads.jsx";
import QuoteBlock from "../components/_ui/QuoteBlock.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`About us`}</h1>
    <p><strong parentName="p">{`Pergola`}</strong>{` `}<em parentName="p">{`(per-go-la)`}</em></p>
    <p>{`Derived from the latin ‘Pergula’ and first used in the C17th to describe the traditional masonry columns of the Italian Renaissance period.`}</p>
    <QuoteBlock mdxType="QuoteBlock" />
    <p>{`Today, pergolas are more likely to be made of wood and are used as a support structure for vines and climbing plants. Think of it as an extension to your home, where for a number of months a year, a pergola lets you enjoy the outdoors in all its glory. Whilst in urban areas, where gardens may be overlooked, a pergola also extends a measure of privacy.`}</p>
    <p>{`One of the most endearing memories of travelling around southern Europe has to be sitting outside a local bistro, on a warm summer’s day under a beautiful pergola heavy with grapes, sunlight filtering through the cracks in the foliage and surrounded by the scents from the nearby flowers and shrubs. No wonder a cold beer and a simple meal always taste better!`}</p>
    <p>{`Whilst this ‘Mediterranean lifestyle’ is fantastic on holiday there is no reason why we should not have a piece of the good life in our own English gardens, particularly given our seemingly longer and hotter summers! The current economic difficulties do not have to mean that growing families have to cram into limited space. If obtaining planning permission to contract builders for a permanent extension or deciding to move and upgrade to a bigger house sounds like too much hard work, here at Perfect Pergolas we have a cost effective solution to cater for an expanding family and an improved way of life.`}</p>
    <h4>{`Look at some of the benefits`}</h4>
    <ol>
      <li parentName="ol">{`Extra outside space that looks great and offers protection`}</li>
      <li parentName="ol">{`Cost effective`}</li>
      <li parentName="ol">{`No planning permission (local conditions may apply)`}</li>
      <li parentName="ol">{`Complements your home`}</li>
      <li parentName="ol">{`A ‘greener’ outdoor lifestyle`}</li>
      <li parentName="ol">{`Takes advantage of our better summers`}</li>
      <li parentName="ol">{`Adds value to your house`}</li>
      <li parentName="ol">{`Extra privacy when entertaining outdoors`}</li>
      <li parentName="ol">{`Only timber from sustainable sources is used`}</li>
    </ol>
    <p><strong parentName="p">{`...plus they all come with a ten year guarantee!`}</strong></p>
    <h2>{`Downloads and support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      